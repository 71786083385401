export function addComma(num: number) {
  return new Intl.NumberFormat("ja-JP").format(num);
}

export function addTax(num: number) {
  return Math.floor(num * 1.1);
}

// 件数表示
export function roundCount(count: number) {
  // 100件未満はそのまま
  if (count < 100) return count;
  let digit = 1;
  // 10000件以上なら1000の位で切り捨て
  if (count >= 10000) digit = 1000;
  // 1000件以上なら100の位で切り捨て
  else if (count >= 1000) digit = 100;
  // 100件以上なら10の位で切り捨て
  else if (count >= 100) digit = 10;
  return Math.floor(count / digit) * digit;
}

// webstorageが使用可能かチェック
export function isActiveStorage(type: "localStorage" | "sessionStorage") {
  try {
    const storage = window[type];
    const x = "__test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    console.log("webStorageが利用できません");
    return false;
  }
}

export function isCloudflare() {
  return process.env.NITRO_PRESET?.match(/cloudflare/);
}

// 施工例画像のURLを生成
export function getSekouImagePath(cd: number, num?: number) {
  const str_cd = cd.toString().padStart(8, "0");
  const str_num = num ? num.toString().padStart(2, "0") : "01";

  return `https://static.famitei.co.jp/sekou_img/img_s/${str_cd.slice(0, 3)}/${str_cd.slice(3, 6)}/${str_cd}_${str_num}.jpg`;
}

// プラン画像のURLを生成
export function getPlanImagePath(cd: number, num?: number) {
  const str_cd = cd.toString().padStart(6, "0");
  const str_num = num ? num.toString() : "1";
  return `https://s3.ap-northeast-1.amazonaws.com/famitei.sys-public/famitei/plan/${str_cd}_${str_num}.jpg `;
}

// 施工例詳細のURLを生成
export function getSekouDetailPath(cd: number, num?: number) {
  const str_cd = cd.toString().padStart(8, "0");
  let path = `/sekou/detail/${str_cd}`;
  // 1はデフォルトの画像なので、URLには反映しないでおく
  if (num && 1 < num) {
    path += `_${num.toString().padStart(2, "0")}`;
  }
  return path;
}

// ブログ詳細のURLを生成
export function getBlogDetailPath(blog_id: string, blog_num: number) {
  const str_blog_num = blog_num.toString().padStart(8, "0");

  return `/BLOG/${blog_id}/blog.php/${str_blog_num}`;
}

// レビュー詳細のURLを生成
export function getReviewDetailPath(kouji_cd: number) {
  const str_cd = kouji_cd.toString().padStart(6, "0");
  return `/letter_e.php/l${str_cd}`;
}
